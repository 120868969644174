import React from 'react';
import { useSelector } from 'react-redux';
import DeleteDialog from '../DeleteDialog';
import UploadDialog from '../UploadDialog';
import ConfirmDialog from '../ConfirmDialog';
import PreviewWarnDialog from '../PreviewWarnDialog';

const MainDialog = () => {
  const dialogVisible = useSelector((state) => state.app.dialogVisible);

  let dialog = null;
  switch (dialogVisible) {
    case ConfirmDialog.NAME:
      dialog = <ConfirmDialog />;
      break;
    case DeleteDialog.NAME:
      dialog = <DeleteDialog />;
      break;
    case UploadDialog.NAME:
      dialog = <UploadDialog />;
      break;
    case PreviewWarnDialog.NAME:
      dialog = <PreviewWarnDialog />;
      break;
    default:
      break;
  }

  return dialog;
};

export default React.memo(MainDialog);
