import RoutingField from './RoutingField';
import SegmentationField from './SegmentationField';
import PersonSelect from './PersonSelect';
import DirektverbindungViaField from './DirektverbindungViaField';
import ServicesTypesField from './ServicesTypesField';
import VelovermietungField from './VelovermietungField';
import CoordinatesPickerField from './CoordinatesPickerField';
import FormSectionTitledField from './FormSectionTitledField';

export default {
  RoutingField,
  SegmentationField,
  DirektverbindungViaField,
  serviceTypes: ServicesTypesField,
  velovermietung: VelovermietungField,
  coordinatesPicker: CoordinatesPickerField,
  PersonSelect,
  FormSectionTitledField,
};
