import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import { List, Divider, Drawer as MuiDrawer } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCenter } from 'ol/extent';
import {
  MdMap,
  MdSearch,
  MdFilterList,
  MdViewList,
  MdCloudDownload,
  MdCloudUpload,
  MdPublish,
  MdAddCircleOutline,
} from 'react-icons/md';
import Feature from 'ol/Feature';
import DrawControl from 'ole/src/control/draw';
import { toLonLat } from 'ol/proj';
import SidebarMenuItem from './SidebarMenuItem';
import BooleanFilter from '../Filters/BooleanFilter';
import TimeFilter from '../Filters/TimeFilter';
import SelectFilter from '../Filters/SelectFilter';
import SearchFilter from '../Filters/SearchFilter';
import Search from '../Search';
import UploadDialog from '../UploadDialog';
import customSbItems from '../CustomSidebarItems';
import fetchStops from '../../utils/fetchStops';

import {
  setListVisible,
  setDialogVisible,
  setSelectedFeature,
  setSelectableFeatures,
  setFeatures,
  setSidebarOpen,
  setIsPreviewActive,
} from '../../model/app/actions';
import { setCenter } from '../../model/map/actions';
import { getFeatureProperty } from '../../utils/featureUtils';
import ConfirmDialog from '../ConfirmDialog';
import { ReactComponent as MenuOpener } from '../../images/opener.svg';
import { SIDEBAR_WIDTH } from '../../utils/constants';
import useIsGeomTopic from '../../utils/useIsGeomTopic';

const propTypes = {
  filterConfig: PropTypes.arrayOf(PropTypes.shape()),
  oleConfig: PropTypes.shape().isRequired,
};

const defaultProps = {
  filterConfig: null,
};

const drawerPaperProps = {
  top: 51,
  height: 'calc(100% - 51px)',
};

const openedMixin = (theme) => ({
  width: `${SIDEBAR_WIDTH}px !important`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: `${SIDEBAR_WIDTH}px !important`,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...drawerPaperProps,
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...drawerPaperProps,
      ...closedMixin(theme),
    },
  }),
}));

const renderFilterComponent = (conf) => {
  switch (conf.type) {
    case 'boolean':
      return <BooleanFilter filterConfig={conf} />;

    case 'date':
      return <TimeFilter filterConfig={conf} />;

    case 'select':
      return <SelectFilter filterConfig={conf} />;

    case 'search':
      return <SearchFilter filterConfig={conf} />;

    default:
      return (
        <span style={{ color: 'red' }}>{`Unknown filter "${conf.type}"`}</span>
      );
  }
};

const STOP_FINDER_FIELD = 'stationsbezeichnung';

const Sidebar = ({ filterConfig, oleConfig }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state) => state.app.sidebarOpen);
  const topic = useSelector((state) => state.app.topic);
  const schema = useSelector((state) => state.app.schema);
  const backendApiUrl = useSelector((state) => state.app.backendApiUrl);
  const apiKey = useSelector((state) => state.app.apiKey);
  const features = useSelector((state) => [
    ...state.app.features,
    ...state.app.routingFeatures.filter(
      (f) => f.get('graph') === 'osm' && f.getGeometry().getType() !== 'Point',
    ),
  ]);
  const isListVisible = useSelector((state) => state.app.isListVisible);
  const dialogVisible = useSelector((state) => state.app.dialogVisible);
  const apiEndpoints = useSelector((state) => state.app.apiEndpoints);
  const ole = useSelector((state) => state.app.ole);
  const center = useSelector((state) => state.map.center);
  const [hasGeom, hasRoute] = useIsGeomTopic();

  const drawControl =
    ole &&
    ole
      .getControls()
      .getArray()
      .find((c) => c instanceof DrawControl);

  const {
    key,
    search,
    allowTilePublication,
    openFormOnAdd,
    customSidebarItems,
  } = topic;
  const renderSearch = useCallback(() => {
    if (search === 'stationFinder') {
      return (
        <Search
          searchEndpoint="https://api.geops.io/stops/v1"
          onSelect={(stop) => {
            const feature = features.find(
              (f) =>
                getFeatureProperty(f, STOP_FINDER_FIELD) === stop.get('name'),
            );

            dispatch(setSelectedFeature(feature));
            dispatch(setSelectableFeatures([]));

            const ext = feature
              ? feature.getGeometry().getExtent()
              : stop
                  ?.getGeometry()
                  ?.clone()
                  .transform('EPSG:4326', 'EPSG:3857')
                  .getExtent();
            if (ext) {
              dispatch(setCenter(getCenter(ext)));
            }

            dispatch(setIsPreviewActive(false));
          }}
          onInputChange={(e, val, abortController) =>
            fetchStops(
              val,
              apiKey,
              abortController,
              `&ref_location=${toLonLat(center)}`,
            )
          }
        />
      );
    }
    if (search === 'default') {
      return (
        <Search
          searchEndpoint={`${backendApiUrl}/${key}/form`}
          onSelect={(feat) => {
            const feature = features.find(
              (f) => f.get('id') === feat.get('id'),
            );
            dispatch(setSelectedFeature(feature));
            dispatch(setSelectableFeatures([]));
            if (feature && feature.getGeometry()) {
              const ext = feature.getGeometry().getExtent();
              dispatch(setCenter(getCenter(ext)));
            }
            dispatch(setIsPreviewActive(false));
          }}
        />
      );
    }
    return null;
  }, [backendApiUrl, dispatch, features, key, search, apiKey, center]);

  const sidebarItems = useMemo(() => {
    const items = [];
    if (schema && search) {
      items.push(
        <SidebarMenuItem
          key="search"
          icon={<MdSearch focusable={false} />}
          sidebarOpen={sidebarOpen}
          title={t('Suchen')}
          onClick={() => dispatch(setSidebarOpen(true))}
        >
          {renderSearch()}
        </SidebarMenuItem>,
      );
    }
    if (filterConfig) {
      items.push(
        <React.Fragment key="filter">
          <SidebarMenuItem
            icon={<MdFilterList focusable={false} />}
            sidebarOpen={sidebarOpen}
            title={t('Filtern')}
            onClick={() => dispatch(setSidebarOpen(true))}
          >
            <>
              {filterConfig &&
                filterConfig.map((conf) => (
                  <div key={conf.label}>{renderFilterComponent(conf)}</div>
                ))}
            </>
          </SidebarMenuItem>
          <Divider />
        </React.Fragment>,
      );
    }
    if (hasGeom || hasRoute) {
      items.push(
        <React.Fragment key="view-toggler">
          <SidebarMenuItem
            icon={
              isListVisible ? (
                <MdMap focusable={false} />
              ) : (
                <MdViewList focusable={false} />
              )
            }
            sidebarOpen={sidebarOpen}
            title={isListVisible ? t('Kartenansicht') : t('Listenansicht')}
            onClick={() => {
              dispatch(setIsPreviewActive(false));
              dispatch(setListVisible(!isListVisible));
            }}
          />
          <Divider />
        </React.Fragment>,
      );
    }
    if (apiEndpoints && apiEndpoints.files) {
      items.push(
        <React.Fragment key="import-export">
          <SidebarMenuItem
            icon={<MdCloudDownload focusable={false} />}
            sidebarOpen={sidebarOpen}
            title={t('Daten exportieren')}
            href={`${backendApiUrl}/${topic.key}/files/export_database`}
            onClick={() => {}}
          />

          <SidebarMenuItem
            icon={<MdCloudUpload focusable={false} />}
            sidebarOpen={sidebarOpen}
            title={t('Daten importieren')}
            onClick={() => {
              dispatch(
                setDialogVisible(
                  dialogVisible === UploadDialog.NAME
                    ? null
                    : UploadDialog.NAME,
                ),
              );
            }}
          />
        </React.Fragment>,
      );
    }
    if (
      apiEndpoints &&
      Object.keys(apiEndpoints).some((endpoint) => /^vtiles/.test(endpoint))
    ) {
      items.push(
        <React.Fragment key="publish">
          <SidebarMenuItem
            icon={<MdPublish focusable={false} />}
            sidebarOpen={sidebarOpen}
            title={t('Vector Tiles publizieren')}
            onClick={() => {
              dispatch(setDialogVisible(ConfirmDialog.NAME));
            }}
            disabled={!allowTilePublication}
            disabledTitle={t(
              'Die Publikation der Daten ist nur auf Produktion möglich.',
            )}
          />
          <Divider />
        </React.Fragment>,
      );
    }
    if (oleConfig.canAddGeometries && !hasGeom && !hasRoute) {
      items.push(
        <SidebarMenuItem
          key="add-feature-list"
          title={t('Element hinzufügen')}
          icon={<MdAddCircleOutline focusable={false} />}
          sidebarOpen={sidebarOpen}
          onClick={() => {
            const feature = new Feature();
            dispatch(setSelectedFeature(feature));
            dispatch(setFeatures([...features, feature]));
            dispatch(setIsPreviewActive(false));
          }}
        />,
      );
    }

    if (
      oleConfig.canAddGeometries &&
      ((hasGeom && !isListVisible) || hasRoute)
    ) {
      items.push(
        <SidebarMenuItem
          key="add-feature-map"
          toggleButton
          buttonProps={{
            value: 'geom',
            selected: drawControl && drawControl.getActive(),
          }}
          title={t('Feature hinzufügen')}
          icon={<MdAddCircleOutline focusable={false} />}
          sidebarOpen={sidebarOpen}
          onClick={() => {
            if (openFormOnAdd) {
              const feature = new Feature();
              dispatch(setSelectedFeature(feature));
            } else {
              dispatch(setSelectedFeature());
              if (drawControl) {
                if (drawControl.getActive()) {
                  drawControl.deactivate();
                } else {
                  drawControl.activate();
                }
              }
            }
            dispatch(setIsPreviewActive(false));
          }}
        />,
      );
    }

    if (customSidebarItems?.length) {
      customSidebarItems.forEach((item) => {
        const CustomItem = customSbItems[item.component];
        if (CustomItem) {
          items.splice(
            item.position,
            0,
            <CustomItem key={`${item.component}-${item.position}`} />,
          );
        } else {
          // eslint-disable-next-line no-console
          console.error(`${item.component} is not a valid custom sidebar item`);
        }
      });
    }

    return items;
  }, [
    schema,
    search,
    filterConfig,
    hasGeom,
    hasRoute,
    apiEndpoints,
    oleConfig.canAddGeometries,
    isListVisible,
    customSidebarItems,
    sidebarOpen,
    t,
    renderSearch,
    dispatch,
    backendApiUrl,
    topic.key,
    dialogVisible,
    allowTilePublication,
    features,
    drawControl,
    openFormOnAdd,
  ]);

  return (
    <Drawer variant="permanent" open={sidebarOpen} elevation={18}>
      <List>{sidebarItems}</List>
      <List style={{ marginTop: `auto`, padding: 0 }}>
        <Divider />
        <SidebarMenuItem
          sidebarOpen={sidebarOpen}
          onClick={() => dispatch(setSidebarOpen(!sidebarOpen))}
          icon={
            <MenuOpener
              style={{ transform: sidebarOpen ? 'rotate(180deg)' : null }}
            />
          }
          title={t('Menü schliessen')}
        />
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
