/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Errors from '../Errors';

const propTypes = {
  formData: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  uiSchema: PropTypes.shape(),
};

const defaultProps = {
  formData: null,
  required: false,
  uiSchema: {},
};

const useStyles = makeStyles({
  tooltip: {
    fontSize: '0.9rem',
    lineHeight: 'initial',
  },
});

const errorLength =
  'Sollte einer 7-stelligen U-Nummer oder E-Nummer entsprechen.';
const errorNotFound = 'Diese Person existiert nicht.';
const errorRequired = 'Dieses Feld ist obligatorisch.';

/**
 * A person has only uri field as possible required field.
 */
const PersonSelect = (props) => {
  const { formData, schema, onChange, required, uiSchema } = props;
  const classes = useStyles();
  const [inputLabel, setInputLabel] = useState(formData?.label);
  const isRequired = required || !!uiSchema['ui:required'];
  // Update input when the selected feature change.
  useEffect(() => {
    setInputLabel(formData?.label);
  }, [formData]);

  const onInputChange = useCallback(
    ({ target: { value } }) => {
      const abortController = new AbortController();
      if (value && value.length === 7) {
        const uri = `${schema['x-target-list']}${value}/`;
        fetch(uri, {
          credentials: 'include',
          signal: abortController.signal,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.sbb_uid) {
              const label = `${res.first_name} ${res.last_name} (${res.sbb_uid})`;
              onChange({ uri, label });
            } else if (res.detail) {
              onChange({ label: value });
            }
          })
          .catch(() => {
            onChange({ label: value });
          });
      } else if (!value) {
        // Reset the status of the uri field so you can save.
        onChange({});
      } else {
        onChange({ label: value });
      }
      return () => {
        abortController.abort();
      };
    },
    [onChange, schema],
  );

  const errors = [];
  if (isRequired && formData && !formData.uri) {
    errors.push(errorRequired);
  }

  if (
    formData &&
    !formData.uri &&
    formData.label &&
    formData.label.length > 0 &&
    formData.label.length !== 7
  ) {
    errors.push(errorLength);
  } else if (formData && !formData.uri && formData?.label?.length === 7) {
    errors.push(errorNotFound);
  }

  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <Tooltip
              title={
                <div className={classes.tooltip}>
                  Geben Sie eine SBB-Mitarbeiter-ID ein, um nach einer Person zu
                  suchen.
                </div>
              }
              placement="left"
            >
              <PermIdentityIcon />
            </Tooltip>
          ),
        }}
        label={uiSchema['ui:title'] || schema.title}
        value={inputLabel || ''}
        onChange={onInputChange}
        required={isRequired}
        error={!!errors.length}
      />
      <Errors errors={{ __errors: errors }} />
    </>
  );
};

PersonSelect.propTypes = propTypes;
PersonSelect.defaultProps = defaultProps;

export default React.memo(PersonSelect);
