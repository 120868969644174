import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { MdCloudDone, MdErrorOutline } from 'react-icons/md';
import InputFile from '../widgets/InputFile/InputFile';
import Dialog from '../Dialog';
import {
  setActiveButton,
  cleanError,
  uploadFile,
  UPLOAD_FILE,
} from '../../model/app/actions';

const NAME = 'upload';

const readErrors = (errors) => {
  return (
    <p>
      {Object.entries(errors).map((zeile) => {
        return (
          <div>
            <p>
              <b>{zeile[0]}</b>
            </p>
            <ul>
              {Object.entries(zeile[1]).map((error) => (
                <li>{`${error[0]} - ${error[1]}`}</li>
              ))}
            </ul>
          </div>
        );
      })}
    </p>
  );
};

const useStyles = makeStyles({
  uploadSuccess: {
    minWidth: '350px',
    display: 'flex',
    color: 'green',

    '& svg': {
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
  },
  uploadError: {
    display: 'flex',
    color: '#f44336',
  },
  uploadErrorTitle: {
    fontWeight: 'bold',
    '& svg': {
      width: '20px',
      height: '20px',
      marginRight: '15px',
    },
  },
  uploadErrorBody: {
    overflowY: 'auto',
    maxHeight: '250px',
    flexDirection: 'column',
    margin: '0 !important',
    paddingBottom: '5px',
    paddingLeft: '35px',

    '& ul': {
      marginBlockStart: '0.3em',
      marginBlockEnd: '0.3em',
    },
    '& p': {
      marginBlockStart: '0.3em',
      marginBlockEnd: '0.3em',
    },
  },
});

function UploadDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [file, setFile] = useState();
  const [statusMsg, setStatusMsg] = useState();
  const [uploading, setUploading] = useState(false);
  const error = useSelector((state) => state.app.error);
  const uploadedData = useSelector((state) => state.app.uploadedData);
  const dialogVisible = useSelector((state) => state.app.dialogVisible);

  const renderErrors = () =>
    error.errors
      ? readErrors(error.errors)
      : error.reason.split('\n').map((item) => <p>{item}</p>);

  // We display success message if upload succeeds.
  useEffect(() => {
    if (uploading && uploadedData) {
      setStatusMsg({
        type: 'SUCCESS',
        message: t('Import erfolgreich'),
      });
      setUploading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedData]);

  // We display error message if upload fails.
  useEffect(() => {
    if (uploading && error) {
      setStatusMsg({
        type: 'ERROR',
        message: error && error.type === UPLOAD_FILE ? renderErrors() : null,
      });
      setUploading(false);
      dispatch(cleanError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // We reinitalize the dialog before closing it
  // We reinitalize setActiveButton when dialog is unmounted.
  useEffect(() => {
    if (dialogVisible !== NAME) {
      setStatusMsg();
      setUploading(false);
      dispatch(setActiveButton());
    }
    return () => {
      dispatch(setActiveButton());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const renderBody = () => {
    return (
      <>
        <div>
          {t(
            'Bei einem Import werden die vorhandenen Daten komplett überschrieben.',
          )}
        </div>
        <InputFile
          accept=".csv"
          uploading={uploading}
          file={file}
          statusMsg={statusMsg}
          isWidget={false}
          onChange={(filesToUpload) => {
            setStatusMsg();
            setFile(filesToUpload[0]);
          }}
        />
        {statusMsg && statusMsg.type === 'SUCCESS' && (
          <div className={classes.uploadSuccess}>
            <MdCloudDone focusable={false} />
            {statusMsg.message} -{file.name}
          </div>
        )}
        {statusMsg && statusMsg.type === 'ERROR' && (
          <>
            <div
              className={`${classes.uploadError} ${classes.uploadErrorTitle}`}
            >
              <MdErrorOutline focusable={false} />
              {t('Fehler beim Importieren von')} {file.name}
            </div>
            <div
              className={`${classes.uploadError} ${classes.uploadErrorBody}`}
            >
              {statusMsg.message ? statusMsg.message : null}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <Dialog
      isModal
      title={<b>{t('Daten importieren')}</b>}
      body={renderBody()}
      disableBackdropClick
      footer={
        uploading || statusMsg ? null : (
          <>
            <Button
              className="ms-dialog-footer-button"
              disabled={uploading}
              onClick={() => {
                if (!file) {
                  return;
                }
                setUploading(true);
                dispatch(uploadFile(file));
              }}
            >
              {t('Anwenden')}
            </Button>
          </>
        )
      }
    />
  );
}

const memoized = React.memo(UploadDialog);
memoized.NAME = NAME;
export default memoized;
