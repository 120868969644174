import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SIDEBAR_WIDTH } from '../../utils/constants';

const useStyles = makeStyles({
  button: {
    padding: '0px !important',
    width: SIDEBAR_WIDTH,
    '& svg': {
      width: 30,
      height: 30,
      margin: 5,
    },
  },
  sidebarContent: {
    padding: '0 15px 15px 20px',
  },
  disabled: {
    opacity: 0.7,
    cursor: 'not-allowed !important',
  },
});

const propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  disabledTitle: PropTypes.string,
  buttonProps: PropTypes.shape(), // see mui docs of ToggleButton and Button
};

const defaultProps = {
  children: null,
  href: null,
  disabled: false,
  disabledTitle: null,
  buttonProps: {},
};

const SidebarMenuItem = ({
  title,
  icon,
  onClick,
  children,
  href,
  disabled,
  disabledTitle,
  buttonProps,
}) => {
  const classes = useStyles();
  const sidebarOpen = useSelector((state) => state.app.sidebarOpen);
  return (
    <>
      <ListItemButton
        classes={{
          root: `${classes.button} ${disabled ? classes.disabled : ''}`,
        }}
        // do not use 'disabled' prop, to be still able to use 'title' on hover
        onClick={() => !disabled && onClick()}
        title={disabled && disabledTitle ? disabledTitle : title}
        href={href}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText sx={{ whiteSpace: 'initial', paddingRight: 2 }}>
          {disabled ? disabledTitle || title : title}
        </ListItemText>
      </ListItemButton>
      {sidebarOpen && children && (
        <Box className={classes.sidebarContent}>{children}</Box>
      )}
    </>
  );
};

SidebarMenuItem.defaultProps = defaultProps;
SidebarMenuItem.propTypes = propTypes;
export default SidebarMenuItem;
