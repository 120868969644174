import { Style, Stroke } from 'ol/style';
import uiSchemas from '../uiSchemas';
import { toDateString } from '../utils';

const { REACT_APP_BACKEND_URL } = process.env;

const choicesFromArray = (arr, attr) => {
  const choices = {};
  arr.forEach((item) => {
    choices[item[attr]] = item[attr];
  });

  // sort by key and return
  return Object.entries(choices)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
};

const regionColors = {
  Ost: '#2F9F48',
  Süd: '#DC320A',
  West: '#FFCC00',
  Mitte: '#A083C7',
};

const fallbackRegionColor = '#00FFFF';

const regionSelect = {
  type: 'select',
  field: 'region__name',
  label: 'Regionen',
  multiple: true,
  fetchChoices: () => {
    return fetch(
      `${REACT_APP_BACKEND_URL}/anlagenverantwortliche/region/form/`,
      { credentials: 'include' },
    )
      .then((res) => res.json())
      .then((res) => choicesFromArray(res, 'region'));
  },
};

const nlSelect = {
  type: 'select',
  field: 'nl__code',
  label: 'Niederlassung',
  multiple: true,
  fetchChoices: () => {
    return fetch(`${REACT_APP_BACKEND_URL}/anlagenverantwortliche/nl/form/`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => choicesFromArray(res, 'code'));
  },
};

const avSelect = {
  type: 'select',
  field: 'av',
  label: 'AV',
  multiple: true,
  fetchChoices: () => {
    return fetch(`${REACT_APP_BACKEND_URL}/anlagenverantwortliche/form/`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => choicesFromArray(res, 'av'));
  },
};

const rolleSelect = {
  type: 'select',
  field: 'rolle',
  label: 'Rolle',
  multiple: true,
  fetchChoices: () => {
    return fetch(`${REACT_APP_BACKEND_URL}/anlagenverantwortliche/ch/form/`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => choicesFromArray(res, 'rolle'));
  },
};

const regionRolleSelect = {
  type: 'select',
  field: 'rolle',
  label: 'Rolle',
  multiple: true,
  fetchChoices: () => {
    return fetch(
      `${REACT_APP_BACKEND_URL}/anlagenverantwortliche/region/form/`,
      {
        credentials: 'include',
      },
    )
      .then((res) => res.json())
      .then((res) => choicesFromArray(res, 'rolle'));
  },
};

const regionUnterRolleSelect = {
  type: 'select',
  field: 'unterrolle',
  label: 'Unterrolle',
  multiple: true,
  fetchChoices: () => {
    return fetch(
      `${REACT_APP_BACKEND_URL}/anlagenverantwortliche/region/form/`,
      {
        credentials: 'include',
      },
    )
      .then((res) => res.json())
      .then((res) => choicesFromArray(res, 'unterrolle'));
  },
};

const lineSelect = {
  type: 'search',
  field: 'line_number',
  label: 'Line number',
};

const anlagenverantwortliche = {
  key: 'anlagenverantwortliche',
  permissionTopic: 'anlagenverantwortliche',
  name: 'Anlagenverantwortliche',
  oleConfig: {
    canAddGeometries: true,
  },
  styleFunction: (feat) =>
    new Style({
      stroke: new Stroke({
        width: 5,
        color: regionColors[feat.get('region')] || fallbackRegionColor,
      }),
    }),
  getFeatureTitle: (feature) => {
    return feature.get('name');
  },
  uiSchema: uiSchemas.anlagenverantwortliche,
};

const toExport = (baseLayers) => [
  {
    ...anlagenverantwortliche,
    baseLayers,
    filterConfig: [regionSelect, nlSelect, avSelect, lineSelect],
    allowTilePublication: process.env.REACT_APP_ENVIRONMENT === 'prod',
    getFeatureTitle: (f) => {
      /* eslint-disable camelcase */
      const { line_number, km_start, km_end } = f.get('line_segment') || {};
      return `Linie ${line_number} (${km_start} - ${km_end})`;
    },
    listConfig: {
      getColumns: () => [
        { field: 'region', headerName: 'Region', flex: 1 },
        { field: 'nl', headerName: 'Niederlassung', flex: 1 },
        { field: 'av', headerName: 'AV', flex: 1 },
        { field: 'linie', headerName: 'Linie', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        region: feature.get('region'),
        nl: feature.get('nl'),
        av: feature.get('av'),
        linie: feature.get('line_segment')?.line_number,
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
  },
  {
    ...anlagenverantwortliche,
    key: 'anlagenverantwortliche/ch',
    name: 'Anlagenverantwortliche Schweiz',
    filterConfig: [rolleSelect],
    baseLayers,
    oleConfig: {
      canAddGeometries: true,
    },
    getFeatureTitle: (feature) => feature.get('rolle'),
    listConfig: {
      getColumns: () => [
        { field: 'rolle', headerName: 'Rolle', flex: 1 },
        { field: 'person', headerName: 'Person', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        rolle: feature.get('rolle'),
        person: (feature.get('person') || {}).label,
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
    uiSchema: uiSchemas.anlagenverantwortlicheSchweiz,
  },
  {
    ...anlagenverantwortliche,
    key: 'anlagenverantwortliche/nl',
    name: 'Anlagenverantwortliche Niederlassung',
    oleConfig: {
      canAddGeometries: true,
    },
    getFeatureTitle: (feature) => feature.get('code'),
    filterConfig: [regionSelect, { ...nlSelect, field: 'code' }, rolleSelect],
    listConfig: {
      getColumns: () => [
        { field: 'region', headerName: 'Region', flex: 1 },
        { field: 'niederlassung', headerName: 'Niederlassung', flex: 1 },
        { field: 'leiter', headerName: 'Leiter NL', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        region: feature.get('region'),
        niederlassung: feature.get('code'),
        leiter: (feature.get('leiter') || {}).label,
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
    uiSchema: uiSchemas.anlagenverantwortlicheNL,
  },
  {
    ...anlagenverantwortliche,
    key: 'anlagenverantwortliche/region',
    name: 'Anlagenverantwortliche Regionen',
    oleConfig: {
      canAddGeometries: true,
    },
    getFeatureTitle: (feature) => feature.get('region'),
    filterConfig: [regionSelect, regionRolleSelect, regionUnterRolleSelect],
    listConfig: {
      getColumns: () => [
        { field: 'region', headerName: 'Region', flex: 1 },
        { field: 'rolle', headerName: 'Rolle', flex: 1 },
        { field: 'unterrolle', headerName: 'Unterrolle', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'modified_at', headerName: 'Bearbeitet am', flex: 1 },
        { field: 'modified_by', headerName: 'Bearbeitet durch', flex: 1 },
      ],
      getRow: (feature) => ({
        region: feature.get('region'),
        rolle: feature.get('rolle'),
        unterrolle: feature.get('unterrolle'),
        name: (feature.get('person') || {}).label,
        modified_at: toDateString(feature.get('modified_at')),
        modified_by: feature.get('modified_by'),
      }),
    },
    uiSchema: uiSchemas.anlagenverantwortlicheRegion,
  },
];

export default toExport;
