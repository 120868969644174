import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '../Dialog';
import {
  setNotification,
  setDialogVisible,
  setCurrentPublication,
} from '../../model/app/actions';
import getCookie from '../../utils/getCookie';

function ConfirmDialog() {
  const topic = useSelector((state) => state.app.topic);
  const backendApiUrl = useSelector((state) => state.app.backendApiUrl);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Dialog
      isModal
      title={<b>{t('Achtung')}</b>}
      body={
        <div>
          {t(
            'Sie werden eine neue Version der Daten veröffentlichen. Sind Sie sicher?',
          )}
        </div>
      }
      footer={
        <>
          <Button
            className="ms-dialog-footer-button"
            onClick={() => {
              fetch(`${backendApiUrl}/-/gitlab/`, {
                method: 'POST',
                body: JSON.stringify({
                  topic: topic.publishTopic || topic.key,
                }),
                credentials: 'include',
                headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': getCookie('csrftoken'),
                },
              })
                .then((response) => {
                  const { status, statusText } = response;
                  if (status === 200) {
                    dispatch(
                      setNotification({
                        type: 'info',
                        message: 'Publikation läuft...',
                        hideDuration: null,
                      }),
                    );
                  } else {
                    dispatch(
                      setNotification({
                        type: 'error',
                        message: statusText,
                        hideDuration: null,
                        onCloseButtonClick: () => {
                          dispatch(setNotification());
                        },
                        closeEvents: ['clickaway'],
                      }),
                    );
                  }
                  return response.json();
                })
                .then((data) => {
                  dispatch(setCurrentPublication(data));
                })
                .catch(() => {
                  dispatch(
                    setNotification({
                      type: 'error',
                      message: 'Problem beim Publizieren',
                      hideDuration: null,
                      onCloseButtonClick: () => {
                        dispatch(setNotification());
                      },
                      closeEvents: ['clickaway'],
                    }),
                  );
                  return null;
                });
              dispatch(setDialogVisible());
            }}
          >
            {t('Anwenden')}
          </Button>
          <Button
            className="ms-dialog-footer-button"
            onClick={() => {
              dispatch(setDialogVisible());
            }}
          >
            {t('Abbrechen')}
          </Button>
        </>
      }
    />
  );
}
const memoized = React.memo(ConfirmDialog);
memoized.NAME = 'confirm';
export default memoized;
